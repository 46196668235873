import React from "react";
import resumeFile from "../documents/resume.pdf";


const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "November 2022 - January 2023",
      title: "Machine Learning",
      place: "AICore",
      desc: "Created web scrapers and learnt core machine learning concepts. Using docker to deploy the project. Also, learnt how to document code and write code abiding by pep8 standards.",
    },
    {
      yearRange: "September 2018 - July 2022",
      title: "Electrical and Electronic Engineering (2:2)",
      place: "Aston University",
      desc: "Learnt many mathematical concepts that are used in machine learning. Created a two-wheel balancing robot as a final year project. In a team project created a LED matrix, which received acclaim from peers and lead to the team being hired by the university to create more units.",
    },
    {
      yearRange: "September 2015 - September 2018",
      title: "Level 3 Electricla and Electronic Engineering (D*D*D)",
      place: "Birmingham Metropolitan College",
      desc: "Created a Nixie tube clock as a final year project using Arduino Uno integrated with multiplexers. 3D printed the shell for the clock and designed it in Cinema 4D.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "August 2022 - February 2023",
      title: "Data Engineer",
      place: "Sparta Global",
      desc: "Worked on multiple group projects. With a notable project whereas a team of 5 we created user stories, ERD, ETL pipeline and got insights from the cleaned data. With the findings being presented in a group presentation. ",
    },
    {
      yearRange: "May 2022 - July 2022",
      title: "Code + Math Tutor",
      place: "Strive Math (YC S21)",
      desc: "Taught children maths through programming and gamification. Guiding children to replicate the game and allowing them freedom to experiment as they wished. ",
    },
    {
      yearRange: "February 2021 - May 2022",
      title: "Founder",
      place: "Peacaps",
      desc: "Created a 3D printed product that matured enough to receive bulk orders from American online retailers. The product was designed and made possible with the help of 10 outsourced individuals. Each using their talents to work on different parts of the product.",
    },
  ];

  const skills = [
    {
      name: "Agile & Scrum",
      years: 1,
    },
    {
      name: "Azure SQL",
      years: 1,
    },
    {
      name: "MongoDB",
      years: 1,
    },
    {
      name: "Python",
      years: 4,
    },
    {
      name: "MATLAB",
      years: 4,
    },
    {
      name: "C++",
      years: 4,
    },
    {
      name: "Docker",
      years: 1,
    },
    {
      name: "Selenium",
      years: 1,
    },
    {
      name: "AWS: EC2, S3, RDS, Lambda",
      years: 1,
    },
    {
      name: "SwiftUI",
      years: 1,
    },
    {
      name: "JavaScript, HTML, CSS",
      years: 1,
    },
    {
      name: "GitHub",
      years: 2,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">&lt; {skill.years} Years</span>
                </p>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
